.App .title {
  font-size: 26px;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-top: 31px;
  margin-top: 25px;
  color: #333;
}

.App .Content {
  width: 80vw;
  margin: auto;
}
.home_form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  & .flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  & .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  & input {
    &[type="text"] {
      width: 100%;
    }
  }

  // Styling for url input
  & .home_url input {
    &[name="url"] {
      font-size: 17px;
      padding: 10px 16px;

      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      border: 1px solid #ccc;
      border-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

      &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
    }
    &[type="submit"] {
      font-size: 17px;
      padding: 10px 16px;

      display: table-cell;
      color: #fff;
      background-color: #005eaa;
      cursor: pointer;
      border: 1px solid #005091;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      &:hover {
        background-color: #004277;
        border-color: #002e53;
      }
    }
  }

  // Styling for keyword input
  strong {
    font-size: 13px;
  }
  & .home_optional_keyword {
    margin-top: 2em;
    font-size: 12px;
    & label[for="keyword"] {
      padding: 5px 10px;
      font-weight: normal;
      color: #555;
      text-align: center;
      background-color: #eee;
      border: 1px solid #ccc;
    }
    & input {
      padding: 5px 10px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      border: 1px solid #ccc;
      color: #555;
      background-color: #fff;
    }
  }
}

.App header {
  display: flex;
  flex-direction: row;

  padding-left: 80px;
  padding-top: 13px;
  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    padding-top: 0px;
  }
  padding-bottom: 13px;
  background-color: #1b6fb3;

  & .title {
    border-left: 1px solid rgb(255, 255, 255);
    padding-top: 28px;
    padding-left: 27px;

    color: white;
    font-size: 18px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  & a {
    width: 141px;
    margin-top: 8px;
    margin-bottom: 20px;
    margin-right: 54px;
    height: 76px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 0px;
      margin-right: 54px;
    }
    background-image: url("/assets/uni_head_official-colorinverted.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  & img {
    margin-top: 8px;
    margin-bottom: 20px;
    margin-right: 54px;
    height: 76px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 0px;
      margin-right: 54px;
    }
  }
}

#main {
  font-size: 20px;
  padding-left: 40px;
  padding-right: 40px;
  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.App footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fffcf5;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  opacity: 0.5;
  padding: 40px 0px 30px 0px;
  margin-top: 0;
  & section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px 40px;
    background-color: #483b56;
    color: white;
    font-size: 10px;
    font-weight: bold;
    & a {
      padding: 0 3px 0 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      text-decoration: none;
    }

    // Styling for keyword input
    strong {
      font-size: 13px;
    }
    & .home_optional_keyword {
      margin-top: 2em;
      font-size: 12px;
      & label {
        padding: 5px 10px;
        font-weight: normal;
        color: #555;
        text-align: center;
        background-color: #eee;
        border: 1px solid #ccc;
      }
      & input {
        padding: 5px 10px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        border: 1px solid #ccc;
        color: #555;
        background-color: #fff;
      }
    }
  }
}
.App {
  position: relative;
  min-height: 100vh;
}
