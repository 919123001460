#notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h1 {
    font-size: 3vh;
  }
  & > img {
    width: 40vw;
  }
}
