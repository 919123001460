.Login {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-direction: column;
  width: 30vw;
  max-width: 500px;
  min-width: 200px;
  align-items: center;

  & > input {
    &[name="email"],
    &[name="password"] {
      width: calc(100% - 16px);
      max-width: 500px;
      margin: 5px 0 5px 0;
      font-size: 17px;
      padding: 10px 0 10px 16px;

      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      border: 1px solid #ccc;
      border-radius: 8px;

      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

      &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
    }

    &[type="submit"] {
      width: 100%;
      max-width: 500px;
      margin: 5px;
      font-size: 17px;
      padding: 10px 16px;

      display: table-cell;
      color: #fff;
      background-color: #005eaa;
      cursor: pointer;
      border: 1px solid #005091;
      border-radius: 8px;
      //border-radius: 0;
      &:hover {
        background-color: #004277;
        border-color: #002e53;
      }
    }
  }
}

.Logout {
  float: right;
  margin: 5px 0;
  & > input {
    //position: absolute;
    float: right;
    margin: 5px 0;
    //width: 100%;
    //display: flex;
    &[type="button"] {
      align-items: flex-end;
      font-size: 17px;
      padding: 10px 16px;
      //width: 100%;
      display: table-cell;
      color: #fff;
      background-color: #005eaa;
      cursor: pointer;
      border: 1px solid #005091;
      border-radius: 8px;
      //border-radius: 0;
      &:hover {
        background-color: #004277;
        border-color: #002e53;
      }
    }
  }
}

.Logout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 35px;
}
